<template>
  <b-container fluid>
    <b-row>
      <iq-card class="bg-transparent shadow-none w-100">
              <EcommerceFilter class="mt-2" :responsive="false" />
         <!-- <div class="d-flex align-items-center justify-content-between pl-2 pr-3">
           <b-col md="12" class="p-0 d-none d-lg-block">
            </b-col>
         </div> -->
      </iq-card>
    </b-row>
  </b-container>
</template>
<script>
import algoliasearch from 'algoliasearch/lite'
import 'instantsearch.css/themes/reset-min.css'
import 'instantsearch.css/themes/algolia-min.css'
import EcommerceFilter from './EcommerceFilter'
import { APP_ID, APP_KEY } from '../../../config/algolia'
import { core } from '../../../config/pluginInit'
import NoResults from '../../../components/core/NotFound/NoResults'
import ResetFilter from './ResetFilter'
import ProductList from './ProductList'
import ProductGrid from './ProductGrid'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Listing',
  mounted () {
    core.index()
  },
  components: {
    ProductGrid,
    EcommerceFilter,
    NoResults,
    ResetFilter,
    ProductList
  },
  computed: {
    ...mapGetters({
      stateCart: 'Ecommerce/cartState',
      stateWishlist: 'Ecommerce/wishlistState'
    })
  },
  data () {
    return {
      listType: 'grid',
      searchClient: algoliasearch(
        APP_ID,
        APP_KEY
      )
    }
  },
  methods: {
    /* objectID is check cart and other functionality */
    ...mapActions({
      addToCart: 'Ecommerce/addToCartAction',
      addToWishlist: 'Ecommerce/addToWishlistAction'
    }),
    checkCart (item) {
      const cartItem = this.stateCart.find(cart => cart.objectID === item.objectID)
      return cartItem !== undefined && cartItem !== null
    },
    wishCart (item) {
      const wishlistItem = this.stateWishlist.find(cart => cart.objectID === item.objectID)
      return wishlistItem !== undefined && wishlistItem !== null
    }
  }
}
</script>
<style>
  .ecommerce .modal-open .modal{
    padding: 0 !important;
  }
  .ecommerce .modal-dialog {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    max-width:none !important;
  }
  .ecommerce .modal-content {
    border: unset;
    height: auto !important;
    min-height: 100% !important;
    border-radius: 0 !important;
  }
  .content-page {
    margin-top: 10px;
  }
.container-fluid {
  padding: 0 20px !important;
}
</style>
