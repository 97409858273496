<template>
  <b-container fluid>
    <div v-show="!showDetails" class="ecommerce-filter">
    <b-row>
      <b-col lg="12">
        <iq-card>
          <!-- <template v-slot:headerTitle>
            <h4 class="card-title">劳动争议判例库</h4>
          </template> -->
          <template v-slot:body>
            <div class="iq-search-bar">
              <form action="#" class="">
                <p class="all-crumbs">劳动法规检索 > 劳动争议判例库</p>
                <!-- <label for="validationDefault01">关键字</label> -->
                <el-select class="select-val" v-model="titleVal">
                  <el-option
                    v-for="item in titleList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
                <input
                  type="text"
                  class="text search-input"
                  v-model="globalSearch"
                  placeholder="请输入关键词"
                />
                <el-button
                  class="btn mr-1 btn-primary rounded-pill seeAbout"
                  type="submit"
                  @click="selectList"
                >
                  查询
                </el-button>
                <el-button
                  class="btn mr-1 btn-secondary rounded-pill reset"
                  type="submit"
                  @click="reset"
                >
                  重置
                </el-button>
              </form>
            </div>
            <!-- <div class="condition-box">
              <h4 class="card-title" style="display: inline-block">
                已选条件：
              </h4>
              <p
                class="selectedClassName"
                v-for="(items, index) in allList"
                :key="index">
                {{ items.title}}—{{items.label}}
              </p>
            </div> -->
          </template>
        </iq-card>
      </b-col>
      <b-col lg="2">
        <div class="filt-box">
          <div
            :class="`card card${index}`"
            v-for="(item, index) in myList"
            :key="item.title"
          >
            <div class="title">
              <img :src="item.url" alt="" />
              <p>{{ item.label }}</p>
            </div>
            <div class="select-box">
              <el-tree
                v-if="index == 0"
                :data="item.children"
                ref="tree"
                show-checkbox
                node-key="id"
                @check="handleCheckChange"
                :default-expanded-keys="defaultShowNodes"
                :props="defaultProps"
              >
              </el-tree>
              <div class="card-box" v-if="index == 1">
                <el-checkbox-group v-model="checkList" @change="selectList">
                  <el-checkbox
                    v-for="item2 in item.children"
                    :key="item2.label"
                    :label="item2.label"
                  ></el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="card-box" v-if="index == 2">
                <el-checkbox-group v-model="checkList2" @change="selectList">
                  <el-checkbox
                    v-for="item2 in item.children"
                    :key="item2.label"
                    :label="item2.label"
                  ></el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col lg="10">
        <div class="filter-contenr">
          <div
            class="card"
            @click="toDetails(item2)"
            v-for="item2 in tableData"
            :key="item2.id"
          >
            <div class="title-box">
              <p class="title">{{ item2.name }}</p>
              <p class="status">{{ item2.trialProcedure }}</p>
            </div>
            <div class="digest-box">
              <p>[智能摘要]</p>
              <p>
                <span>{{ item2.trialCourt }}</span>
                <span>{{ item2.caseNo }}</span>
                <span>{{item2.refereeTime.substring(0,10)}}</span>
              </p>
            </div>
            <p class="val">{{ item2.reason }}</p>
          </div>
        </div>
        <el-pagination
          v-if="dataSize != 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 15, 20]"
          :page-size="interPage"
          layout="prev, pager, next, jumper"
          :total="dataSize"
          background
        ></el-pagination>
      </b-col>
    </b-row>
    </div>
    <detailsPage ref="details" v-if="showDetails" @secedeDetails="secedeDetails"></detailsPage>
  </b-container>
</template>
<script>

// 引入函数
import { getCauseofaction, getPrecedenttList } from "@/FackApi/api/admin";
import { Loading } from "element-ui";
import detailsPage from './EcommerceFilter/detailsPage.vue';
export default {
  name: "EcommerceFilter",
  components: {
    detailsPage,
  },
  mounted() {
  },
  data() {
    return {
      globalSearch: "",
      myList: [],
      defaultProps: {
        value: 'id',
        label: 'name',
        children: 'childs',
        expandTrigger: 'hover'
      },
      currentPage: 1,
      interPage: 10,
      dataSize: 0,
      checkList: [],
      checkList2: [],
      ayList: [],
      allList: [],
      defaultShowNodes: [],
      tableData: [],
      showDetails: false,
      titleVal: 1,
      titleList: [
        { value: 1, label: "关键字" },
        { value: 2, label: "案号" },
      ],
      areaList: [ // 地域列表
        {label: "最高人民法院", value: "最高人民法院"},
        {label: "北京市", value: "北京市"},
        {label: "天津市", value: "天津市"},
        {label: "河北省", value: "河北省"},
        {label: "山西省", value: "山西省"},
        {label: "内蒙古自治区", value: "内蒙古自治区"},
        {label: "辽宁省", value: "辽宁省"},
        {label: "吉林省", value: "吉林省"},
        {label: "黑龙江省", value: "黑龙江省"},
        {label: "上海市", value: "上海市"},
        {label: "江苏省", value: "江苏省"},
        {label: "浙江省", value: "浙江省"},
        {label: "安徽省", value: "安徽省"},
        {label: "福建省", value: "福建省"},
        {label: "江西省", value: "江西省"},
        {label: "山东省", value: "山东省"},
        {label: "河南省", value: "河南省"},
        {label: "湖北省", value: "湖北省"},
        {label: "广东省", value: "广东省"},
        {label: "广西壮族自治区", value: "广西壮族自治区"},
        {label: "海南省", value: "海南省"},
        {label: "重庆市", value: "重庆市"},
        {label: "四川省", value: "四川省"},
        {label: "贵州省", value: "贵州省"},
        {label: "云南省", value: "云南省"},
        {label: "西藏自治区", value: "西藏自治区"},
        {label: "陕西省", value: "陕西省"},
        {label: "甘肃省", value: "甘肃省"},
        {label: "青海省", value: "青海省"},
        {label: "宁夏回族自治区", value: "宁夏回族自治区"},
        {label: "新疆维吾尔自治区", value: "新疆维吾尔自治区"},
      ],
      yearList: [
        {label: "2023", value: 2023},
        {label: "2022", value: 2022},
        {label: "2021", value: 2021},
        {label: "2020", value: 2020},
        {label: "2019", value: 2019},
        {label: "2018", value: 2018},
        {label: "2017", value: 2017},
        {label: "2016", value: 2016},
        {label: "2015", value: 2015},
        {label: "2014", value: 2014},
        {label: "2013", value: 2013},
        {label: "2012", value: 2012},
        {label: "2011", value: 2011},
        {label: "2010", value: 2010},
      ],
    };
  },
  created() {
    this.getAppForm();
  },
  methods: {
    // 获取查询条件集合
    getAppForm() {
      getCauseofaction().then((res) => {
        // console.log(res,1010)
        if (res.code === 0) {
          this.myList = [
            {label: "案由", children: res.result},
            {label: "地域", children: this.areaList},
            {label: "年份", children: this.yearList},
          ];
          this.myList[0].url = require("@/assets/images/fgzck/icon-1.png");
          this.myList[1].url = require("@/assets/images/fgzck/icon-2.png");
          this.myList[2].url = require("@/assets/images/fgzck/icon-3.png");
          let id = this.myList[0].children[0].childs[0].id;
          this.defaultShowNodes = [this.myList[0].children[0].childs[0].id];
          this.$nextTick(()=> {
            this.$refs.tree[0].setChecked(id,true,false);
            this.handleCheckChange();
          })
        }
      });
    },
    reset() {
    },
    // 通过筛选条件查询判例
    getAppFormList() {
      this.tableData = [];
      this.dataSize = 0;
      let params = {
        info: {
          causeOfActions: [],
          regions: [],
          years: [],
          queryType: this.titleVal,
          queryString: this.globalSearch,
        },
        params: `${this.currentPage}/${this.interPage}`,
      }
      this.allList.forEach(item => {
        if (item.title == "案由") {
          params.info.causeOfActions.push(item.id);
        } else if (item.title == "地域") {
          params.info.regions.push(item.label);
        } else if (item.title == "年份") {
          params.info.years.push(item.label);
        }
      })
      let loadingInstance = Loading.service({ fullscreen: true });
      getPrecedenttList(params).then((res) => {
        loadingInstance.close();
        if (res.code === 0) {
          let data = res.result;
          this.dataSize = data.totalCount;
          this.tableData = data.items;
        }
      });
    },
    handleCheckChange() {
      this.ayList = this.$refs.tree[0].getCheckedNodes();
      // this.ayList.forEach((item, index) => {
      //   if (item.childs) {
      //     this.ayList.splice(index, 1);
      //   }
      // });
      this.selectList();
    },
    selectList() {
      this.allList = [];
      this.ayList.forEach((item) => {
        this.allList.push({
          title: "案由",
          label: item.label,
          id: item.id,
        });
      });
      this.checkList.forEach((item2, index2) => {
        // let id = "";
        // for (let i in this.myList[1]["children"]) {
        //   if (this.myList[1]["children"][i].label == item2) {
        //     id = this.myList[1]["children"][i].id;
        //     break;
        //   }
        // }
        this.allList.push({
          title: "地域",
          label: item2,
          index: index2,
          // id: id,
        });
      });
      this.checkList2.forEach((item3, index3) => {
        // let id = "";
        // for (let i in this.myList[2]["children"]) {
        //   if (this.myList[2]["children"][i].label == item3) {
        //     id = this.myList[2]["children"][i].id;
        //     break;
        //   }
        // }
        this.allList.push({
          title: "年份",
          label: item3,
          index: index3,
          // id: id,
        });
      });
      // console.log(this.allList);
      this.getAppFormList();
    },
    toDetails(item) {
      this.showDetails = true;
      this.$nextTick(()=>{
        this.$refs.details.getDetailInfo(item);
      })
    },
    handleSizeChange(psize) {
      this.interPage = psize;
      this.getAppFormList();
    },
    handleCurrentChange(cpage) {
      this.currentPage = cpage;
      this.getAppFormList();
    },
    secedeDetails() {
      this.showDetails = false;
    },
  },
};
</script>

<style lang="less" scoped>
.ecommerce-filter {
  .row {
    .col-lg-12 {
      padding-left: 6px;
      padding-right: 0;
    }
    .col-lg-2 {
      padding-left: 6px;
      width: 17%;
    }
    .col-lg-10 {
      padding-right: 0;
      width: 80%;
    }
  }
}
// .iq-search-bar {
//   border-bottom: 1px solid #e7e7e7;
//   padding-bottom: 30px !important;
//   margin-bottom: 30px;
// }
.condition-box {
  display: flex;
  flex-wrap: wrap;
  h4 {
    font-weight: 600;
  }
  .selectedClassName {
    max-width: 400px;
    height: 32px;
    line-height: 32px;
    padding: 0 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
/deep/ .select-val {
  width: 100px;
  margin-right: 20px;
  border: none;
  .el-input {
    width: 100%;
    border: none;
    .el-input__inner {
      width: 100%;
      border: none;
      color: #333;
      background: #fff;
      padding-left: 10px;
    }
  }
}
.search-input {
  width: 300px !important;
  margin: 0 50px 0 10px !important;
  background: #eaf5ff !important;
}
.seeAbout {
  width: 100px;
  border-radius: 50px;
}
.reset {
  width: 100px;
  border-radius: 50px;
  background: #c0c4cc;
  margin-left: 10px !important;
}
.selectedClassName {
  padding: 5px 20px;
  margin-right: 10px;
  color: #837af3;
  font-size: 0.8em;
  display: inline-block;
  border-radius: 50px;
  background: linear-gradient(to right, #f0e4fd, #e6e4fd) !important;
}
.filt-box {
  // width: 250px;
  width: 100%;
  // height: 60vh;
  overflow-y: auto;
  border-radius: 20px !important;
  // overflow: hidden;
  box-shadow: 0px 0px 10px 0px rgba(152,172,190,0.2);
  .card {
    .title {
      display: flex;
      align-items: center;
      padding: 18px 20px;
      box-sizing: border-box;
      background-color: #fff;
      margin-bottom: 2px;
      img {
        width: 16px;
        margin-right: 10px;
      }
      p {
        font-size: 18px;
      }
    }
    /deep/ .select-box {
      // max-height: 320px;
      overflow-y: auto;
      padding: 0 20px;
      box-sizing: border-box;
      margin: 16px 0;
      .el-tree {
        .el-tree-node__content {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      .el-icon-caret-right {
        // padding: 0 0 6px 0;
        font-size: 18px;
        margin-left: 10px;
      }
      .el-checkbox {
        margin-left: -38px;
        margin-top: 8px;

        .el-checkbox__input {
          .el-checkbox__inner {
            font-size: 14px;
          }
            .el-checkbox__inner:hover {
              border: 1px solid #dcdfe6;
            }
        }
        .is-indeterminate {
          .el-checkbox__inner {
            background-color: #827af3;
            border-color: #827af3;
          }
        }
      }
        .is-checked {
          .is-checked {
            .el-checkbox__inner {
            background-color: #827af3;
            border-color: #827af3;
            }
          }
        }
      .el-tree-node__label {
        width: 160px;
        font-size: 14px;
        margin-left: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
      }
      .card-box {
        .el-checkbox-group {
          display: flex;
          flex-direction: column;
          .el-checkbox {
            margin-bottom: 10px;
            .el-checkbox__label {
              font-size: 14px;
            }
          }
          .is-checked {
            .is-checked {
              .el-checkbox__inner {
                background-color: #827af3;
                border-color: #827af3;
              }
            }
            .el-checkbox__label {
              color: #333;
            }
          }
        }
      }
    }
  }
  .card0 {
    .title {
      background: linear-gradient(270deg, #f0e4fd 0%, #e6e4fd 100%);
      p {
        color: #837af3;
      }
    }
  }
  .card1 {
    .title {
      background: #eefef2;
      p {
        color: #29b446;
      }
    }
  }
  .card2 {
    .title {
      background: #fffbee;
      p {
        color: #ffc22d;
      }
    }
  }

  // ::-webkit-scrollbar {
  //     width: 8px;
  //   }
  //   ::-webkit-scrollbar-thumb {
  //     border-radius: 10px;
  //     box-shadow: inset 0 0 5px #fff;
  //     background: #E9E9E9;
  //   }
  //   ::-webkit-scrollbar-track {
  //     box-shadow: inset 0 0 5px #fff;
  //     border-radius: 0;
  //     background: #fff;
  //   }
}
.filter-contenr {
  width: 98%;
  // height: 60vh;
  overflow-y: auto;
  margin-left: 2%;
  .card {
    padding: 30px;
    box-sizing: border-box;
    background-color: #fff;
    margin-bottom: 20px;
    border-radius: 18px;
    box-shadow: 0px 0px 10px 0px rgba(152,172,190,0.2);
    cursor: pointer;
    .title-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 22px;
      border-bottom: 1px solid #dddfe6;
      margin-bottom: 20px;
      .title {
        font-size: 16px;
        font-weight: bold;
        color: #333;
      }
      .status {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 80px;
        padding: 0 10px;
        height: 32px;
        font-size: 14px;
        color: #837AF3;
        background: linear-gradient(270deg, #F0E4FD 0%, #E6E4FD 100%);
        border-radius: 20px;
      }
    }
    .digest-box {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      p {
        font-size: 14px;
        color: #909399;
        span {
          margin-left: 20px;
        }
      }
    }
    .val {
      font-size: 14px;
      color: #333;
    }
  }
}
/deep/ .el-pagination {
  text-align: center;
  margin-top: 30px;
  .btn-prev,
  .btn-next {
    background-color: #fff;
  }
  .el-pagination__sizes {
    .el-select {
      .el-input {
        .el-input__inner:hover {
          border: 1px solid #6477c8;
          border-color: #6477c8;
        }
      }
      .is-focus {
        .el-input__inner {
          border: 1px solid #6477c8;
        }
      }
    }
  }
  .el-pager {
    .number,
    .el-icon {
      background-color: #fff;
    }
    .number:hover {
      color: #6477c8 !important;
    }
    .active {
      background-color: #6477c8 !important;
    }
    .active:hover {
      color: #fff !important;
    }
  }
}
.el-select-dropdown__item {
  color: #6477c8;
}
.all-crumbs {
  margin-bottom: 20px !important;
}
p {
  margin-bottom: 0 !important;
}
</style>
<style>
.contract {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  background: linear-gradient(to right, #f0e4fd, #e6e4fd) !important;
  padding: 20px 20px 0;
}
.children {
  padding: 20px;
}
.contract1 {
  border-radius: 0;
  overflow: hidden;
  background: linear-gradient(to right, #edfff1, #edfff1) !important;
  padding: 10px 20px 0;
}
.contract2 {
  border-radius: 0;
  overflow: hidden;
  background: linear-gradient(to right, #fffaef, #fffaef) !important;
  padding: 10px 20px 0;
}
.contract .iq-card-body {
  background: white;
}
</style>
