<template>
  <div class="details-page">
    <div class="conter-box">
      <div class="left">
        <p class="title">
          <span v-html="myInfo.name"></span>
        </p>
        <div class="subtitle">
          <p class="subitem">案由：<span>{{myInfo.causeOfActionName}}</span></p>
          <p class="subitem">案号：<span>{{myInfo.caseNo}}</span></p>
          <p class="subitem">发布日期：<span>{{myInfo.refereeTime}}</span></p>
        </div>
        <div class="val-box" v-html="myInfo.content"></div>
      </div>
      <div class="right">
        <i class="el-icon-circle-close" @click="secede"></i>
        <div class="title">
          <i class="el-icon-caret-right"></i>
          基本信息
        </div>
        <div class="content">
          <p class="val-p">审理法院：{{myInfo.trialCourt}}</p>
          <p class="val-p">案件类型：{{myInfo.caseType}}</p>
          <p class="val-p">案由：{{myInfo.causeOfActionName}}</p>
          <p class="val-p">审理程序：{{myInfo.trialProcedure}}</p>
          <p class="val-p">裁判日期：{{myInfo.refereeTime}}</p>
          <p class="val-p">当事人：{{myInfo.litigant}}</p>
        </div>
        <div class="title">
          <i class="el-icon-caret-right"></i>
          法律依据
        </div>
        <div class="content" v-html="myInfo.lawBasis"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPrecedentDetail,
} from "@/FackApi/api/admin";
export default {
  name: "detailsPage",
  components: {
  },
  data() {
    return {
      myInfo: {},
    }
  },
  created() {
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    getDetailInfo(info) {
      // let res = await getDetail(info);
      // if (res.code == 0) {
      //   this.myInfo = res.data;
      // }
      getPrecedentDetail(info.id).then((res) => {
        if (res.code == 0) {
        let data = res.result;
        data.refereeTime = data.refereeTime.substring(0,10);
        this.myInfo = data;
      }
      })
    },
    getAppForm() {
      cjcaseLaw().then((res) => {
        // console.log(res,1010)
        if (res.code === 0) {
          this.myList = res.data;
          this.myList[0].url = require("@/assets/images/fgzck/icon-1.png");
          this.myList[1].url = require("@/assets/images/fgzck/icon-2.png");
          this.myList[2].url = require("@/assets/images/fgzck/icon-3.png");
        }
      });
    },
    secede() {
      this.$emit('secedeDetails')
    },
  }
}
</script>

<style lang="less" scoped>
  .details-page {
    .conter-box {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      background-color: #fff;
      .left {
        width: 1200px;
        padding: 20px;
        box-sizing: border-box;
        .title {
          text-align: center;
          font-size: 16px;
          color: #000;
          line-height: 32px;
          padding-bottom: 20px;
        }
        .subtitle {
          display: flex;
          justify-content: space-between;
          padding-bottom: 20px;
          border-bottom: 1px dashed #f0f0f0;
          .subitem {
            flex: 1;
            font-size: 12px;
            text-align: center;
            color: #777;
          }
        }
        .val-box {
          padding: 20px 0 20px 20px;
          line-height: 30px;
          font-size: 14px;
          color: #000;
          text-indent: 2em;
        }
      }
      .right {
        position: relative;
        padding: 50px 10px 20px 10px;
        box-sizing: border-box;
        border-left: 4px solid #f5f5f5;
        .el-icon-circle-close {
          position: absolute;
          right: 30px;
          top: 30px;
          font-size: 30px;
          cursor:pointer;
        }
        .title {
          font-size: 16px;
          line-height: 32px;
          color: #000;
          padding-bottom: 20px;
          margin-top: 20px;
          .el-icon-caret-right {}
        }
        /deep/ .content {
          padding-left: 18px;
          color: #666;
          font-size: 14px;
          line-height: 26px;
          .val-p {
            margin-bottom: 10px;
          }
          div {
            p {
              margin-top: 24px;
            }
          }
        }
      }
    }
  }
</style>

